1<template>
    <div class="body" ref="ygh-content-box" id="ygh-content-box">
        <mytable
            @preview="(evt) => $refs.DrawerLabel.open(evt)"
            ref="mytable"
            @showDraw="(evt) => $refs.StepForm.open(evt)"
        />
        <StepForm @refresh="$refs.mytable.refresh()" ref="StepForm" />
        <DrawerLabel ref="DrawerLabel" />
        <!-- <myform v-if="change == 0" /> -->
    </div>
</template>

<script>
import DrawerLabel from './drawerLabel.vue'
import myform from './form.vue'
import StepForm from './stepForm.vue'
import mytable from './table.vue'

export default {
    components: { mytable, myform, StepForm, DrawerLabel },
    data() {
        return {
            btck: 0,
        }
    },
    methods: {},
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.font {
    font-size: 16px;
}

.body {
    position: relative;
    margin: 0 auto !important;
    background: #fff;
}
.comp {
    padding: 33px 40px 0;
}
.bts {
    margin-left: 17px;
}
.btns {
    margin-right: 17px;
    font-size: 16px;
}
.fred {
    color: #f56c6c;
}
</style>
