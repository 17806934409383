<template>
    <div class="box">
        <div class="big">
            <div>
                <a-card :bordered="false">
                    <s-table
                        ref="table"
                        :columns="columns"
                        :data="loadData"
                        :alert="false"
                        :rowKey="(record) => record.id"
                    >
                        <span slot="operator">
                            <div class="flex" style="margin: 0; align-items: center">
                                <div>
                                    <a-input-search
                                        placeholder="请输入名称"
                                        @search="onSearch"
                                        class="search"
                                        v-model="queryParam.name"
                                    >
                                        <a-button slot="enterButton" icon="search"> </a-button>
                                    </a-input-search>
                                </div>
                                <a-button
                                    style="background: none; color: #40a9ff"
                                    type="primary"
                                    shape="round"
                                    size="small"
                                    @click="$emit('showDraw', { type: 'add', data: {} })"
                                    icon="plus"
                                    >新增模板</a-button
                                >
                            </div>
                        </span>
                        <span slot="isSystem" slot-scope="text">
                            {{ text ? '是' : '否' }}
                        </span>
                        <!-- 创建人姓名  -->
                        <span slot="userId" slot-scope="text, record">
                            <OpenData type="userName" :openid="record.userId" :corpid="record.corpId"></OpenData>
                        </span>
                        <span slot="updateTime" slot-scope="text">
                            {{ text && text.substr(0, text.length - 4) }}
                        </span>
                        <span slot="createTime" slot-scope="text">
                            {{ text && text.substr(0, text.length - 4) }}
                        </span>
                        <!-- {{ text && text.substr(0, 10) }} -->
                        <span slot="action" slot-scope="text, record">
                            <a class="bts" @click="copyTemplate(record)">复制</a>
                            <a
                                v-if="!record.isSystem"
                                class="bts"
                                @click="$emit('showDraw', { type: 'edit', data: record })"
                                >编辑</a
                            >
                            <a v-else class="bts" @click="$emit('showDraw', { type: 'show', data: record })">详情</a>
                            <a-popconfirm
                                v-if="!record.isSystem"
                                placement="topRight"
                                title="确定删除模板吗！"
                                @confirm="() => delDraft(record)"
                            >
                                <a class="bts">删除</a>
                            </a-popconfirm>
                        </span>
                    </s-table>
                </a-card>
            </div>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line camelcase
import { copySys, documentFormDeleteApi, documentFormPageApi } from '@/api/modular/fileStream/documentHead'
import { STable } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
    },
    data() {
        return {
            btck: 0,
            // 查询参数
            queryParam: { documentType: 2, approvalTab: 0 },
            active: 2,
            // 表头
            columns: [
                {
                    title: '模板名称',
                    dataIndex: 'name',
                    ellipsis: true,
                },
                {
                    title: '是否是系统模板',
                    dataIndex: 'isSystem',
                    scopedSlots: { customRender: 'isSystem' },
                },
                {
                    title: '创建时间',
                    dataIndex: 'createTime',
                    scopedSlots: { customRender: 'createTime' },
                },
                {
                    title: '更新时间',
                    dataIndex: 'updateTime',
                    scopedSlots: { customRender: 'updateTime' },
                },
                {
                    title: '创建人',
                    dataIndex: 'userId',
                    scopedSlots: { customRender: 'userId' },
                },
                {
                    title: '操作',
                    width: '150px',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            // 加载数据方法 必须为 Promise 对象
            loadData: (parameter) => {
                return documentFormPageApi(Object.assign(parameter, this.queryParam, { status: this.active }))
                    .then((res) => {
                        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66, res)
                        return res.data
                    })
                    .catch((err) => {
                        console.log(err)
                        return {
                            pageNo: 1,
                            pageSize: 10,
                            rainbow: [],
                            rows: [],
                            totalPage: 0,
                            totalRows: 0,
                        }
                    })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
        }
    },
    created() {
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66)
    },
    methods: {
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        copyTemplate({ id }) {
            copySys(id).then((res) => {
                if (res.code === 200) {
                    this.$message.success('复制成功')
                    this.$refs.table.refresh(true)
                }
            })
        },
        //输入搜索
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        // 删除
        delDraft({ id }) {
            documentFormDeleteApi([id])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('删除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        refresh() {
            this.$refs.table.refresh()
        },
    },
}
</script>

<style lang="less" scoped>
.box {
    padding: 15px 39px 33px;
    height: calc(100% - 17px - 24px);

    .big {
        button {
            font-size: 16px;
        }
    }

    .flex {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 20px 0;
    }

    .search {
        width: 300px;
        border-radius: 4px;
    }
}

::v-deep .ant-table-pagination.ant-pagination {
    text-align: center;
    float: none;
}

::v-deep .ant-card-body {
    padding: 0;
}

.btns {
    margin-right: 17px;
}

.fred {
    color: #f56c6c;
}

.bts {
    margin-right: 10px;
}

.flex0 {
    display: flex;
    align-items: center;
}

.my-item {
    display: inline-block;

    .tags {
        margin-bottom: 1px;
    }
}
</style>
