<template>
  <full-model-view progress-dot :visible="visible" :title="title" :sub-title="subTitle" @cancel="handleCancel">
    <a-spin tip="提交中..." :spinning="confirmLoading">
      <div slot="up-extra">
      </div>
      <div>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="inline">
          <a-form-model-item label="模板名称" prop="name">
            <div style="display: flex;align-items: center;">
              <a-input :readOnly="type === 'show'" style="width:400px" v-model="form.name" />
              <template v-if="type !== 'show'">
                <div style="display: flex;align-items: center;margin-left: 20px;">
                  <a-button style="margin-right: 20px;" type="primary" :loading="confirmLoading" @click="submitForm">保存
                  </a-button>
                  <a-popconfirm placement="topRight" title="确定重置标题个模板吗！" @confirm="() => resetContent">
                    <a-button>重置</a-button>
                  </a-popconfirm>
                </div>
                <!-- <div style="margin-left: 20px;color: #ccc;">
                  提示: 模板年限请输入%YYYY%,以便替换。
                </div> -->
              </template>
            </div>
          </a-form-model-item>
        </a-form-model>
        <div v-if="visible">
          <editor :isOnlyRead="type === 'show'" :content.sync="form.content" ref="editor" />
        </div>
      </div>
    </a-spin>
  </full-model-view>
</template>

<script>
import { Ellipsis, FullModelView } from '@/components'
import {
  documentFormAddApi,
  documentFormEditApi
} from '@/api/modular/fileStream/documentHead'
import { mapState } from 'vuex'
import editor from './editor'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    editor
  },
  computed: {
    ...mapState({
      visible: state => state.bill.postMsg
    })
  },
  data() {
    return {
      title: '新增',
      type:'add',
      subTitle: '红头模板',
      content: '', // 富文本内容
      confirmLoading: false,// 提交loading
      form: {
        id:'',
        name: '',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: ['change', 'blur'] },
        ],
        content: [
          { required: true, message: '请输入模板', trigger: ['change', 'blur'] },
        ]
      },
    }
  },
  beforeDestroy() {
    this.visible && this.$store.commit('bill/setPostMsg', false)
  },
  methods: {
    open({ type, data }) {
      this.form = Object.assign(this.form,data)
      this.type = type
      if (type === 'edit') {
        this.title = '编辑'
      } else if (type === 'show') {
        this.title = '详情'
      } else {
        this.title = '新增'
        //this.$refs.MyForm.clearMessage()
        this.resetContent()
      }
      this.$store.commit('bill/setPostMsg', true)
      this.$nextTick(()=>{
        this.$refs.ruleForm.clearValidate()
      })
    },
    close() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    handleCancel() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    resetContent() {
      this.form =Object.assign({},{
        id:'',
        name: '',
        content: ''
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.type == 'add'){
            this.documentFormAddFun()
          }else if(this.type == 'edit'){
            this.documentFormEditFun()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    documentFormAddFun() {
      this.confirmLoading = true
      documentFormAddApi({...this.form}).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancel()
        } else {
          this.$message.error(res.message)
        }
        this.confirmLoading = false
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    // 编辑
    documentFormEditFun() {
      this.confirmLoading = true
      documentFormEditApi({...this.form}).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancel()
        } else {
          this.$message.error(res.message)
        }
        this.confirmLoading = false
      }).finally((res) => {
        this.confirmLoading = false
      })
    }
  },
  
}
</script>

<style scoped>
.add-steps-box {
  /*width: 1200px;*/
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0px 160px;
  padding-bottom: 20px;
}

.add-content-box {
  margin: 0 auto;
  /* width: 1200px; */
  padding: 0px 160px;
  box-sizing: border-box;
}

.add-content-block {
  margin: 0 auto;
}

.add-steps-actions {
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: space-around;
}
</style>
